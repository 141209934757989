import React from "react"
import { Component } from "react"
import { connect } from "react-redux"

import { CollapsibleSection } from "../components/collapsible-section.js"
import { MetapathAttic } from "./attic.js"
import { MetapathTable } from "./table.js"
import { Loading } from "../components/loading.js"
// path results section component
export class MetapathResults extends Component {
  // initialize component
  constructor() {
    super()

    this.state = {}
    this.state.showMore = false
  }

  // toggle expanded table
  toggleShowMore = (event) => {
    if (event) event.preventDefault()

    this.setState({ showMore: !this.state.showMore })
  }

  // display component
  render() {
    let placeholder = <></>
    if (!this.props.startNode || !this.props.endNode) {
      placeholder = (
        <span className="light">select a source and target node</span>
      )
    } else if (this.props.metapaths.length === 0)
      placeholder = <span className="light">no results to show</span>

    return (
      <CollapsibleSection
        label="Metapaths"
        tooltipText="Metapaths of length <= 3 between the source and target
        node"
      >
        {this.props.metapathLoading ? <Loading color="#02b3e4" /> : ""}
        <MetapathAttic
          showMore={this.state.showMore}
          toggleShowMore={this.toggleShowMore}
        />
        {!this.props.metapathLoading && this.props.metapaths.length > 0 && (
          <MetapathTable showMore={this.state.showMore} />
        )}
        {this.props.metapathLoading ? "" : placeholder}
      </CollapsibleSection>
    )
  }
}
// connect component to global state
MetapathResults = connect((state) => ({
  metapaths: state.metapaths,
  startNode: state.startNode,
  endNode: state.endNode,
  metapathLoading: state.metapathLoading,
}))(MetapathResults)
