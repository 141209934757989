import React from "react"
import { Component } from "react"

import { Button } from "../hetio-frontend-components/build"

import "../css/search-button.css"

// filter button component
export class SearchButton extends Component {
  // initialize component
  constructor(props) {
    super(props)
  }

  // display component
  render() {
    return (
      <Button
        className="node_search_search_button"
        disabled={this.props.disabled}
        onClick={() => this.props.solo()}
      >
        {this.props.name}
      </Button>
    )
  }
}
