import { makeFilenameFriendly } from "../hetio-frontend-components/build"
import { sortCustom } from "../hetio-frontend-components/build"
import { downloadCsv } from "../hetio-frontend-components/build"

// download metapath table
export function download(sourceNode, targetNode, connectivities) {
  if (!connectivities.length) return

  const tableData = makeTable(connectivities)
  const filename = [
    "connectivities",
    makeFilenameFriendly(sourceNode),
    makeFilenameFriendly(targetNode),
  ].join("_")

  downloadCsv(tableData, filename)
}

// make table array out of object
function makeTable(connectivities) {
  let headers = []
  for (const field of Object.keys(connectivities[0])) {
    if (
      typeof connectivities[0][field] === "number" ||
      typeof connectivities[0][field] === "string"
    )
      headers.push(field)
  }

  // remove undesired fields
  headers = headers.filter((header, key) => {
    switch (header) {
      case "path_count":
        return false
      case "heat_count":
        return false

      default:
        return true
    }
  })

  // sort headers in custom order
  const order = ["heatCount", "pathCount"]
  headers = sortCustom(headers, order)

  const table = []
  table.push(headers)
  for (const result of connectivities) {
    const row = []
    for (const header of headers) row.push(result[header])
    table.push(row)
  }
  return table
}
