import React from "react"
import { Component } from 'react';
import { css } from "@emotion/core"
import ScaleLoader from "react-spinners/ScaleLoader"

import '../css/loading.css'

export class Loading extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="sweet-loading">
        <ScaleLoader
          size={50}
          color={this.props.color}
          loading={this.props.loading}
        />
      </div>
    )
  }
}
