import React from "react"
import { Component } from "react"
import { connect } from "react-redux"

import { Context } from "./context.js"
import { Filters } from "./filters.js"
import { SourceNode } from "./source-node.js"
import { TargetNode } from "./target-node.js"

import { SwapButton } from "./swap-button.js"
import { RandomButton } from "./random-button.js"
import { SearchButton } from "./search-button.js"
import { CollapsibleSection } from "../components/collapsible-section.js"

import { fetchAndSetConnectivities } from "../connectivities-results/actions.js"
import { fetchAndsetLoading } from "../app/actions.js"
import { setMetapaths } from "../metapath-results/actions.js"
import { setPaths } from "../path-results/actions.js"
// node search section component
export class NodeSearch extends Component {
  // initialize component
  constructor() {
    super()

    this.state = {}
    this.state.filterString = ""
    this.state.disabled = false
  }

  // update string of metanode abbreviations for search query filter param
  updateFilterString = (string) => {
    this.setState({ filterString: string })
  }

  onSearch() {
    if (!this.state.disabled) {
      if (!this.props.sourceNode.id) {
        alert("Source Node Can not be empty")
        this.setState({ disabled: false })
        return
      }
      if (!(this.props.searchResults.length > 0)) {
        alert("Nodes Can not be empty")
        this.setState({ disabled: false })
        return
      }
      if (this.props.searchResults.length > 0 && this.props.sourceNode.id) {
        this.setState({ disabled: true })
        this.props.dispatch(
          fetchAndsetLoading({
            connectivitiesLoading: true,
            metapathLoading: true,
            pathLoading: true,
          })
        )
        this.props
          .dispatch(
            fetchAndSetConnectivities({
              sourceNodeId: this.props.sourceNode.id,
              targetNodeIds: this.props.searchResults.map((item) => item.id),
              precomputedOnly: this.props.precomputedMetapathsOnly,
              updateUrl: true,
              preserveChecks: false,
            })
          )
          .then(() => {
            this.setState({ disabled: false })
            this.props.dispatch(
              setMetapaths({
                metapath: [],
              })
            )
            this.props.dispatch(
              setPaths({
                paths: [],
              })
            )
            this.props.dispatch(
              fetchAndsetLoading({
                connectivitiesLoading: false,
                metapathLoading: false,
                pathLoading: false,
              })
            )
          })
      }
    }
  }

  // display component
  render() {
    return (
      <Context.Provider
        value={{
          filterString: this.state.filterString,
          updateFilterString: this.updateFilterString,
        }}
      >
        <CollapsibleSection
          label="Node Search"
          tooltipText="Search the database for a source and target node"
        >
          <div className="node-search-block">
            {/*<Filters />*/}
            <SourceNode />
            <div className="node-search-block"></div>
            {/* <SwapButton />*/}
            {/*<RandomButton />*/}
            <TargetNode />
            <button
              className="node_search_search_button"
              disabled={this.state.disabled}
              onClick={() => this.onSearch()}
            >
              Search
            </button>
          </div>
        </CollapsibleSection>
      </Context.Provider>
    )
  }
}
// connect component to global state
NodeSearch = connect((state) => ({
  sourceNode: state.sourceNode,
  targetNode: state.targetNode,
  searchResults: state.searchResults,
  precomputedOnly: state.precomputedOnly,
  connectivities: state.connectivities,
}))(NodeSearch)
