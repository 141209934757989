import React from "react"
import { Component } from "react"
import { connect } from "react-redux"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWindowClose } from "@fortawesome/free-solid-svg-icons"

import { Tooltip } from "../hetio-frontend-components/build"
import { MetanodeChip } from "../components/chips.js"
import { ReactComponent as PathIcon } from "../images/path.svg"

import { fetchTargetNode } from "./actions.js"
import { fetchSearchResults } from "./actions.js"

import "../css/nodes.css"

// dropdown sub-component of search box component
export class Nodes extends Component {
  // initialize component
  constructor(props) {
    super(props)

    this.state = {}
    this.state.selectedItem = null
  }

  componentDidUpdate() {
    if (this.state.selectedItem === null) {
      this.setState({
        selectedItem: 0,
      })
    }
  }

  onClose(index) {
    let searchResults = this.props.searchResults
    searchResults.splice(index, 1)
    this.props.dispatch(fetchSearchResults({ searchResults: searchResults }))
    // unfocus search box on selection
    if (index) document.activeElement.blur()
  }

  onClick(index, value) {
    this.setState({
      selectedItem: this.state.selectedItem === index ? null : index,
    })
    this.props.dispatch(
      fetchTargetNode({
        targetNode: this.state.selectedItem === index ? "" : value,
        updateUrl: false,
      })
    )
    if (index) document.activeElement.blur()
  }

  // display component
  render() {
    return (
      <div className="node_group">
        <div className="small light node_title">
          <Tooltip text="Nodes">
            {this.props.searchResults ? this.props.searchResults.length : 0}
            &nbsp; nodes
          </Tooltip>
        </div>
        <div className="node_menu">
          {this.props.searchResults &&
            this.props.searchResults.length > 0 &&
            this.props.searchResults.map((result, index) => (
              <div
                title={result.primaryName}
                className={
                  "node_item" +
                  (this.state.selectedItem === index
                    ? " node_search_item_selected"
                    : "")
                }
                key={index}
                onClick={() => this.onClick(index, result)}
              >
                <MetanodeChip type={result.metanode} />
                <span className="node_name nowrap">{result.primaryName}</span>
                <div className="node_icon" onClick={() => this.onClose(index)}>
                  <FontAwesomeIcon icon={faWindowClose} />
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

// connect component to global state
Nodes = connect((state) => ({
  targetNode: state.targetNode,
  searchResults: state.searchResults,
}))(Nodes)
