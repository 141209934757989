// reducer for state.metagraph
export function metagraph(state = {}, action) {
  switch (action.type) {
    case "set_definitions":
      return action.payload.metagraph || {}
    default:
      return state
  }
}

// reducer for state.hetioStyles
export function hetioStyles(state = {}, action) {
  switch (action.type) {
    case "set_definitions":
      return action.payload.hetioStyles || {}
    default:
      return state
  }
}

// reducer for state.tooltipDefinitions
export function tooltipDefinitions(state = {}, action) {
  switch (action.type) {
    case "set_definitions":
      return action.payload.tooltipDefinitions || {}
    default:
      return state
  }
}

export function startNode(state = 0, action) {
  switch (action.type) {
    case "set_node":
      return action.payload.startNode || 0
    default:
      return state
  }
}

export function endNode(state = 0, action) {
  switch (action.type) {
    case "set_node":
      return action.payload.endNode || 0
    default:
      return state
  }
}

export function connectivitiesLoading(state = 0, action) {
  switch (action.type) {
    case "set_loading":
      return action.payload.connectivitiesLoading || false
    default:
      return state
  }
}
export function metapathLoading(state = 0, action) {
  switch (action.type) {
    case "set_loading":
      return action.payload.metapathLoading || false
    default:
      return state
  }
}
export function pathLoading(state = 0, action) {
  switch (action.type) {
    case "set_loading":
      return action.payload.pathLoading || false
    default:
      return state
  }
}
