import React from "react"
import { Component } from "react"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { faQuestion } from "@fortawesome/free-solid-svg-icons"
import copy from "copy-to-clipboard"

import { IconButton } from "../hetio-frontend-components/build"
import { DynamicField } from "../hetio-frontend-components/build"
import { Table } from "../hetio-frontend-components/build"
import { connectivitiesChips } from "../components/chips.js"
import { Tooltip } from "../hetio-frontend-components/build"
import { toFixed } from "../hetio-frontend-components/build"
import { toExponential } from "../hetio-frontend-components/build"
import { toComma } from "../hetio-frontend-components/build"
import { toGradient } from "../hetio-frontend-components/build"
import { setConnectivities } from "./actions.js"
import { fetchAndSetMetapathMissingData } from "./actions.js"
import {
  fetchAndSetMetapaths,
  setMetapaths,
} from "../metapath-results/actions.js"
import { fetchAndsetNode } from "../app/actions.js"
import { fetchAndsetLoading } from "../app/actions.js"
import { fetchAndSetPaths, setPaths } from "../path-results/actions"

import "../css/table.css"

// metapath table component
export class ConnectivitiesTable extends Component {
  // display component
  render() {
    const onChange = (newData) => {
      this.props.dispatch(fetchAndsetLoading({ metapathLoading: true }))
      this.props.dispatch(
        setConnectivities({ connectivities: newData, updateUrl: true })
      )
      let metapathChecked = false
      for (const item of newData) {
        if (item.checked) {
          this.props.dispatch(
            fetchAndsetNode({
              startNode: item.startId,
              endNode: item.endId,
            })
          )
          this.props
            .dispatch(
              fetchAndSetMetapaths({
                sourceNodeId: item.startId,
                targetNodeId: item.endId,
                precomputedOnly: true,
                updateUrl: false,
                preserveChecks: true,
              })
            )
            .then(() => {
              this.props.dispatch(
                fetchAndsetLoading({ metapathLoading: false })
              )
            })
          metapathChecked = true
          break
        }
      }
      if (metapathChecked) {
        this.props.dispatch(
          setPaths({
            paths: [],
          })
        )
      } else {
        this.props.dispatch(
          setMetapaths({
            metapath: [],
          })
        )
        this.props.dispatch(fetchAndsetLoading({ metapathLoading: false }))
      }
    }

    const sortFunction = (field) => {
      // sort metapaths
      if (field === "metapath_metaedges") {
        return (a, b, key) => {
          a = a[key]
          b = b[key]
          // first by length
          if (a.length < b.length) return -1
          else if (a.length > b.length) return 1
          else {
            // then alphabetically
            if (a < b) return -1
            else if (a > b) return 1
            else return 0
          }
        }
      }
    }

    let fields = ["checked", "connectivities", "heatCount", "pathCount"]
    const checkboxes = [true]
    const sortables = [false, true, true, true]

    let topContents = []
    let topStyles = []
    let topClasses = []
    let topColspans = []

    let headContents = [
      '',
      // <FontAwesomeIcon className="fa-xs" icon={faCheck} />,
      "Connectivities",
      <>Heat</>,
      <>
        path
        <br />
        count
      </>,
    ]
    const headStyles = [
      { width: 25 },
      { width: 200 },
      { width: 100 },
      { width: 100 },
    ]
    const headClasses = [null, "small left", "small", "small"]
    const headTooltips = [
      "Show/hide all metapaths",
      "connectivities",
      "heatCount",
      "pathCount",
    ]

    {
      /*const compute = (datum) => (
      <Tooltip
        text="The data for this metapath was not pre-computed because it
          was below a certain threshold of importance. Click to compute it now."
      >
        <button
          className="small metapath_results_compute"
          onClick={() =>
            this.props.dispatch(
              fetchAndSetMetapaths({
                sourceNodeId: this.props.sourceNode.id,
                targetNodeId: this.props.targetNode.id,
                precomputedOnly: this.props.precomputedMetapathsOnly,
                updateUrl: true,
                preserveChecks: true,
              })
            )
          }
        >
          <FontAwesomeIcon className="fa-sm" icon={faQuestion} />
        </button>
      </Tooltip>
        )*/
    }
    const bodyContents = [
      <FontAwesomeIcon className="fa-xs" icon={faCheck} />,
      (datum, field, value) => {
        return (
          <DynamicField
            value={connectivitiesChips(datum.connectivities || [], false)}
          />
        )
      },
      (datum, field, value) => {
        return (
          <DynamicField
            value={
              datum.heatCount !== undefined
                ? toComma(datum.heatCount)
                : datum.heatCount
            }
          />
        )
      },
      (datum, field, value) => {
        return (
          <DynamicField
            value={
              datum.pathCount !== undefined
                ? toComma(datum.pathCount)
                : datum.heatCount
            }
          />
        )
      },
    ]
    //  (datum, field, value) => (
    //   <DynamicField
    //     value={value !== undefined ? toComma(value) : compute(datum)}
    //     fullValue={value}
    //   />
    // ),
    // (datum, field, value) => (
    //   <DynamicField
    //     value={value !== undefined ? toExponential(value) : compute(datum)}
    //     fullValue={value}
    //   />
    // ),
    // (datum, field, value) => (
    //   <DynamicField
    //     value={value !== undefined ? toExponential(value) : compute(datum)}
    //     fullValue={value}
    //   />
    // ),

    // (datum, field, value) => ({
    //   background: toGradient(Math.log10(value), [
    //     [-50, "rgba(233, 30, 99, 0.5)"],
    //     [-1, "rgba(255, 255, 255, 0)"],
    //   ]),
    // }),
    const bodyStyles = [null, null, null, null]
    const bodyClasses = [null, "left"]
    const bodyTooltips = [(datum) => "solo click"]

    return (
      <Table
        containerClass={"table_container"}
        className="connectivities_results_table"
        data={this.props.connectivities}
        fields={fields}
        checkboxes={checkboxes}
        radio={true}
        sortables={sortables}
        onChange={onChange}
        sortFunction={sortFunction}
        defaultSortField="path_count"
        defaultSortUp={false}
        dontResort={true}
        topContents={topContents}
        topStyles={topStyles}
        topClasses={topClasses}
        topColspans={topColspans}
        headContents={headContents}
        headStyles={headStyles}
        headClasses={headClasses}
        headTooltips={headTooltips}
        bodyContents={bodyContents}
        bodyStyles={bodyStyles}
        bodyClasses={bodyClasses}
        bodyTooltips={bodyTooltips}
      />
    )
  }
}
// connect component to global state
ConnectivitiesTable = connect((state) => ({
  connectivities: state.connectivities,
  tooltipDefinitions: state.tooltipDefinitions,
  startNode: state.startNode,
  endNode: state.endNode,
}))(ConnectivitiesTable)
