// url for conectivity search specific definitions (p value, dwpc, etc)
// import connectivitySearchDefinitions from './definitions.json';

// url for github data
const github =
  "https://raw.githubusercontent.com/hetio/hetionet/6e08d3039abaad8f6dafe26fe3b143773b0d7e51"

// api endpoint/url for production
const api = "https://search-api.het.io/v1"
// api endpoint/url for testing
// const api = 'http://localhost:3000/v1';
// const api = "api/v1";
// const github = "api/v1";

// get resource at url and parse as json
export async function fetchJson(url, dontCache) {
  try {
    // check if exact query has already been made during this session
    // if so, use cache of that. if not, query server
    const cachedResponse = window.sessionStorage.getItem(url)
    if (cachedResponse && !dontCache) return JSON.parse(cachedResponse)

    const response = await fetch(url)
    let json = {}
    if (response.ok) json = await response.json()
    else {
      console.error(response)
      return
    }

    // save response to cache
    if (!dontCache) window.sessionStorage.setItem(url, JSON.stringify(json))

    return json
  } catch (error) {
    console.error(error, url)
    return
  }
}

// get resource at url and parse as json
export async function fetchByIdJson(url, id, dontCache) {
  try {
    // check if exact query has already been made during this session
    // if so, use cache of that. if not, query server
    // const cachedResponse = window.sessionStorage.getItem(url)
    // if (cachedResponse && !dontCache) return JSON.parse(cachedResponse)

    const response = await fetch(url)
    let json = {}
    if (response.ok) json = await response.json()
    else {
      console.error(response)
      return
    }

    const abbrev = {
      id: id,
      name: json.abbrevs[id],
      label: json.abbrevs[id],
      metanode: json.abbrevs[id],
    }

    // save response to cache
    // if (!dontCache) window.sessionStorage.setItem(url, JSON.stringify(abbrev))

    return abbrev
  } catch (error) {
    console.error(error, url)
    return
  }
}

export async function fetchJsonMetagraph(url, dontCache) {
  try {
    // check if exact query has already been made during this session
    // if so, use cache of that. if not, query server
    const cachedResponse = window.sessionStorage.getItem(url)
    if (cachedResponse && !dontCache) return JSON.parse(cachedResponse)

    const response = await fetch(url)
    let data = {}
    let json = {}
    if (response.ok) {
      data = await response.json()
      json = await composeJSON(data)
    } else {
      console.error(response)
      return
    }

    // save response to cache
    if (!dontCache) window.sessionStorage.setItem(url, JSON.stringify(json))

    return json
  } catch (error) {
    console.error(error, url)
    return
  }
}

export async function fetchJsonNODE(url, dontCache) {
  try {
    // check if exact query has already been made during this session
    // if so, use cache of that. if not, query server
    const cachedResponse = window.sessionStorage.getItem(url)
    if (cachedResponse && !dontCache) return JSON.parse(cachedResponse)

    const response = await fetch(url)
    let data = {}
    let json = {}
    if (response.ok) {
      data = await response.json()
      json = await composeJSON_NODE(data)
    } else {
      console.error(response)
      return
    }

    // save response to cache
    if (!dontCache) window.sessionStorage.setItem(url, JSON.stringify(json))

    return json
  } catch (error) {
    console.error(error, url)
    return
  }
}

export async function fetchJsonMetapaths(url, dontCache) {
  try {
    // check if exact query has already been made during this session
    // if so, use cache of that. if not, query server
    const cachedResponse = window.sessionStorage.getItem(url)
    if (cachedResponse && !dontCache) return JSON.parse(cachedResponse)

    const response = await fetch(url)
    let data = {}
    let json = {}
    if (response.ok) {
      data = await response.json()
      json = await composeMetapaths(data)
    } else {
      console.error(response)
      return
    }

    // save response to cache
    if (!dontCache) window.sessionStorage.setItem(url, JSON.stringify(json))

    return json
  } catch (error) {
    console.error(error, url)
    return
  }
}
export async function fetchJsonConnectivities(url, params, dontCache) {
  try {
    // check if exact query has already been made during this session
    // if so, use cache of that. if not, query server
    const cachedResponse = window.sessionStorage.getItem(url)
    if (cachedResponse && !dontCache) return JSON.parse(cachedResponse)

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
    let data = {}
    let json = {}
    if (response.ok) {
      data = await response.json()
      json = await composeConnectivities(data)
    } else {
      console.error(response)
      return
    }

    // save response to cache
    if (!dontCache) window.sessionStorage.setItem(url, JSON.stringify(json))

    return json
  } catch (error) {
    console.error(error, url)
    return
  }
}

export async function fetchJsonPaths(url, dontCache) {
  try {
    // check if exact query has already been made during this session
    // if so, use cache of that. if not, query server
    const cachedResponse = window.sessionStorage.getItem(url)
    if (cachedResponse && !dontCache) return JSON.parse(cachedResponse)

    const response = await fetch(url)
    let data = {}
    let json = {}
    if (response.ok) {
      data = await response.json()
      json = await composePaths(data)
    } else {
      console.error(response)
      return
    }

    // save response to cache
    if (!dontCache) window.sessionStorage.setItem(url, JSON.stringify(json))

    return json
  } catch (error) {
    console.error(error, url)
    return
  }
}

async function composeJSON(data) {
  const json = {}
  if (data.code === 200) {
    json.metanode_kinds = data.data.map((item) => {
      item = item.replace(/([a-z])([A-Z])/g, "$1 $2") // 大写单词前加空格
      return item
    })
    json.metaedge_tuples = [
      ["Anatomy", "Gene", "downregulates", "both"],
      ["Anatomy", "Gene", "expresses", "both"],
      ["Anatomy", "Gene", "upregulates", "both"],
      ["Compound", "Compound", "resembles", "both"],
      ["Compound", "Disease", "palliates", "both"],
      ["Compound", "Disease", "treats", "both"],
      ["Compound", "Gene", "binds", "both"],
      ["Compound", "Gene", "downregulates", "both"],
      ["Compound", "Gene", "upregulates", "both"],
      ["Compound", "Side Effect", "causes", "both"],
      ["Disease", "Anatomy", "localizes", "both"],
      ["Disease", "Disease", "resembles", "both"],
      ["Disease", "Gene", "associates", "both"],
      ["Disease", "Gene", "downregulates", "both"],
      ["Disease", "Gene", "upregulates", "both"],
      ["Disease", "Symptom", "presents", "both"],
      ["Gene", "Biological Process", "participates", "both"],
      ["Gene", "Cellular Component", "participates", "both"],
      ["Gene", "Gene", "covaries", "both"],
      ["Gene", "Gene", "interacts", "both"],
      ["Gene", "Gene", "regulates", "forward"],
      ["Gene", "Molecular Function", "participates", "both"],
      ["Gene", "Pathway", "participates", "both"],
      ["Pharmacologic Class", "Compound", "includes", "both"],
    ]
    json.kind_to_abbrev = {
      Cytokine: "CY",
      Cell: "CE",
      Protein: "P",
      "Biological Process": "BP",
      "Cellular Component": "CC",
      causes: "c",
      "Pharmacologic Class": "PC",
      "Molecular Function": "MF",
      palliates: "p",
      downregulates: "d",
      expresses: "e",
      Gene: "G",
      covaries: "c",
      upregulates: "u",
      presents: "p",
      Anatomy: "A",
      Symptom: "S",
      Pathway: "PW",
      treats: "t",
      localizes: "l",
      Disease: "D",
      participates: "p",
      binds: "b",
      includes: "i",
      associates: "a",
      Compound: "C",
      interacts: "i",
      resembles: "r",
      regulates: "r",
      "Side Effect": "SE",
      affect_positive_cap: "apc",
      affect_negative_cap: "anc",
      affect_unknown_cap: "auc",
      affect_positive_pac: "app",
      affect_negative_pac: "anp",
      affect_unknown_pac: "aup",
      produces: "p",
      subclass_of: "s",
    }
  }
  return json
}

function composeNODE(data) {
  return data.map((item) => {
    item.metanode = item.label
    // item.url = "";
    // item.identifier = "";
    // item.properties = { url: "", source: "", field: "" };
    return item
  })
}

async function composeJSON_NODE(data) {
  if (data.code === 200) {
    let obj = {}
    obj = data.data

    obj.metanode = obj.label ? obj.label : obj.labels[0]
    obj.properties = {}
    obj.propertyList.map((pt) => {
      obj.properties[pt.key] = pt.value
    })
    obj.name = obj.properties["name"]
    return obj
  }
  return {}
}

async function composeJSON_NODE_TYPE() {
  let obj = {}
  obj = {}

  obj.metanode = obj.label ? obj.label : obj.labels[0]
  obj.properties = {}
  obj.propertyList.map((pt) => {
    obj.properties[pt.key] = pt.value
  })
  obj.name = obj.properties["name"]
  return obj
}

async function composeMetapaths(data) {
  let obj = {}
  if (data.code === 200) {
    obj.source = data.data.source
    obj.target = data.data.target
    obj.path_counts = data.data.metaPaths.map((item) => {
      item.metapath_abbreviation = item.metaPathAbbreviation
      item.heat_count = item.metaHeatCount || "-"
      item.path_count = item.metaPathCount
      item.metapath_metaedges = item.metaPaths.map((mp) => {
        let list = []
        list.push(mp.startLabel)
        list.push(mp.endLabel)
        list.push(mp.relationShip)
        list.push(mp.direction)
        return list
      })
      return item
    })
  }
  return obj
}

async function composeConnectivities(data) {
  let obj = {}
  if (data.code === 200) {
    obj.source = {}
    obj.target = {}
    obj.path_counts = data.data.map((item) => {
      item.path_count = item.pathCount
      item.heat_count = item.heatCount || "-"
      let connectivities = []
      connectivities.push({
        element: "node",
        name: item.startName,
        type: item.startLabel,
      })
      connectivities.push({
        direction: "both",
        element: "edge",
        type: "",
      })
      connectivities.push({
        element: "ellipsis",
        name: "......",
        type: "",
      })
      connectivities.push({
        direction: "both",
        element: "edge",
        type: "",
      })
      connectivities.push({
        element: "node",
        name: item.endName,
        type: item.endLabel,
      })
      item.connectivities = connectivities
      return item
    })
  }
  return obj
}

async function composeMetapaths2(data) {
  let obj = {}
  if (data.code === 200) {
    obj.source = {}
    obj.target = {}
    obj.path_counts = data.data.map((item) => {
      item.metapath_abbreviation = item.metaPathAbbreviation
      item.adjusted_p_value = 0
      item.path_count = item.metaPathCount
      item.metapath_metaedges = item.metaPaths.map((mp) => {
        let list = []
        list.push(mp.startLabel)
        list.push(mp.endLabel)
        list.push(mp.relationShip)
        list.push(mp.direction)
        return list
      })
      return item
    })
  }
  return obj
}

async function composePaths(data) {
  let obj = {}
  if (data.code === 200) {
    // obj = data.data;
    let nodes = Object.assign({}, data.data.nodes)
    obj.nodes = {}
    for (const i in nodes) {
      const node = nodes[i]
      obj.nodes[node.id] = {
        neo4j_id: node.id,
        node_label: node.name,
        metanode: node.label,
        properties: node.properties,
        // properties: { name: node.name },
      }
    }
    let relationShips = Object.assign({}, data.data.relationShips)
    obj.relationships = {}
    for (const i in relationShips) {
      const rs = relationShips[i]
      obj.relationships[rs.id] = {
        neo4j_id: rs.id,
        source_neo4j_id: rs.sourceId,
        target_neo4j_id: rs.targetId,
        properties: {},
        kind: rs.relationShip,
        rel_type: rs.relType,
        directed: rs.direction !== "both" ? true : false,
        properties: rs.properties,
      }
    }
    let paths = Object.assign([], data.data.paths)
    obj.paths = []
    obj.paths = paths
    obj.paths.forEach((path, j) => {
      path.heat_count = path.heatCount || "-"
      path.metapath = path.metaPathAbbreviation
      path.node_ids = []
      path.rel_ids = []
      path.details.forEach((detail, index) => {
        if (index === 0) {
          path.node_ids.push(detail.startId)
          path.node_ids.push(detail.endId)
        } else {
          path.node_ids.push(detail.endId)
        }
      })
      path.node_ids.forEach((nodeId, j) => {
        for (const i in relationShips) {
          const rs = relationShips[i]
          if (
            (path.node_ids[j] === rs.sourceId &&
              path.node_ids[j + 1] === rs.targetId) ||
            (path.node_ids[j + 1] === rs.sourceId &&
              path.node_ids[j] === rs.targetId)
          ) {
            path.rel_ids.push(rs.id)
          }
        }
      })
    })
    return obj
  }

  return obj
}

// get metagraph
export async function getMetagraph() {
  const query = join("api/v1", "label-list")
  return (await fetchJsonMetagraph(query)) || {}
}
export async function getMetagraph2() {
  const query = join(github, "hetnet/json/hetionet-v1.0-metagraph.json")
  return (await fetchJson(query)) || {}
}

// get hetio definitions
export async function getHetioDefinitions() {
  const query = join(window.location.origin, "json/definitions.json")
  return (await fetchJson(query)) || {}
}
export async function getHetioDefinitions2() {
  const query = join(window.location.origin, "json/definitions.json")
  return (await fetchJson(query)) || {}
}

// get hetio styles
export async function getHetioStyles() {
  const query = join(window.location.origin, "json/styles.json")
  return (await fetchJson(query)) || {}
}

// get connectivity search definitions
export function getConnectivitySearchDefinitions() {
  // return connectivitySearchDefinitions || {};
  return {}
}

// lookup node by id
export async function lookupNode(id) {
  // if required parameters not specified, exit
  if (!id && id !== 0) return {}
  const params = new URLSearchParams()
  if (id) params.set("id", id)
  const query = join("api/v1", "info/search", "?" + params.toString())
  return (await fetchJsonNODE(query)) || {}
}

export async function lookupNode2(id) {
  // if required parameters not specified, exit
  if (!id && id !== 0) return {}
  const query = join(api, "node", id)
  return (await fetchJson(query)) || {}
}

export async function getTypeNodes() {
  const query = join(window.location.origin, "json/metagraph.json")
  return (await fetchJson(query)) || {}
}

export async function getTypeNodesById(id) {
  const query = join(window.location.origin, "json/metagraph.json")
  return (await fetchByIdJson(query, id)) || {}
}

// search for nodes by string, and with metanode filter list
// accepts comma-separated list of abbreviations of metanodes to include
export async function searchNodes(searchString, otherNodeId, metanodes) {
  const params = new URLSearchParams()
  // params.set("keyword", "")
  // params.set("metaNodes", "")
  // params.set("otherNode", "")
  params.set("keyword", searchString)
  params.set("otherNode", otherNodeId || otherNodeId === 0 ? otherNodeId : "")
  if (
    !(
      metanodes === null ||
      metanodes === "null" ||
      metanodes === undefined ||
      metanodes === ""
    )
  )
    params.set("metaNodes", metanodes)
  const query = join("api/v3", "node/search", "?" + params.toString())
  const response = await fetchJson(query)
  if (response && response.code === 200) return composeNODE(response.data)
  else return []
}
export async function searchNodes2(searchString, otherNodeId, metanodes) {
  const params = new URLSearchParams()
  if (searchString) params.set("search", searchString)
  if (otherNodeId || otherNodeId === 0) params.set("other-node", otherNodeId)
  if (
    !(
      metanodes === null ||
      metanodes === "null" ||
      metanodes === undefined ||
      metanodes === ""
    )
  )
    params.set("metanodes", metanodes)
  const query = join(api, "node/search", "?" + params.toString())
  const response = await fetchJson(query)
  if (response && response.results) return response.results
  else return []
}

// get random source/target node pair that has any (precomputed) metapaths
export async function getRandomNodePair() {
  const query = join(api, "random-node-pair")
  const response = await fetchJson(query, true)
  return response || {}
}

// search for metapaths by source/target node id
export async function searchMetapaths(
  sourceNodeId,
  targetNodeId,
  complete,
  sourceType,
  targetType
) {
  // if required parameters not specified, exit
  if (
    (!sourceNodeId && sourceNodeId !== 0) ||
    (!targetNodeId && targetNodeId !== 0)
  )
    return []
  const query = join(
    "api/v2",
    "source",
    sourceNodeId,
    "target",
    targetNodeId
    // complete ? "&complete" : ""
  )
  const response = await fetchJsonMetapaths(query, true)
  if (response && response.path_counts) return response.path_counts
  else return []
}

// search for connectivities by source/target node id
export async function searchConnectivities(sourceNodeId, targetNodeIds) {
  // if required parameters not specified, exit
  if (
    (!sourceNodeId && sourceNodeId !== 0) ||
    (!targetNodeIds && !(targetNodeIds.length > 0))
  )
    return []
  const data = {}
  data.sources = [sourceNodeId]
  data.targets = targetNodeIds
  const query = join("api/v2/connect/source/target")
  const response = await fetchJsonConnectivities(query, data, true)
  if (response && response.path_counts) return response.path_counts
  else return []
}

export async function searchMetapaths2(sourceNodeId, targetNodeId, complete) {
  // if required parameters not specified, exit
  if (
    (!sourceNodeId && sourceNodeId !== 0) ||
    (!targetNodeId && targetNodeId !== 0)
  )
    return []

  const query = join(
    api,
    "metapaths",
    "source",
    sourceNodeId,
    "target",
    targetNodeId,
    complete ? "?complete" : ""
  )
  const response = await fetchJson(query)
  if (response && response.path_counts) return response.path_counts
  else return []
}

// search for paths by source/target node id and metapath
export async function searchPaths(sourceNodeId, targetNodeId, metapath) {
  // if required parameters not specified, exit
  if (
    (!sourceNodeId && sourceNodeId !== 0) ||
    (!targetNodeId && targetNodeId !== 0) ||
    !metapath
  )
    return []

  const query = join(
    "api/v2",
    "source",
    sourceNodeId,
    "target",
    targetNodeId,
    "metapath",
    metapath
  )
  const response = await fetchJsonPaths(query)
  return response || []
}

export async function searchPaths2(sourceNodeId, targetNodeId, metapath) {
  // if required parameters not specified, exit
  if (
    (!sourceNodeId && sourceNodeId !== 0) ||
    (!targetNodeId && targetNodeId !== 0) ||
    !metapath
  )
    return []

  const query = join(
    api,
    "paths",
    "source",
    sourceNodeId,
    "target",
    targetNodeId,
    "metapath",
    metapath
  )
  const response = await fetchJson(query)
  return response || []
}

function join(...pieces) {
  return pieces.join("/")
}
