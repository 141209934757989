import React from "react"
import { Component } from "react"
import { connect } from "react-redux"

import { sortCustom } from "../hetio-frontend-components/build"
import { shortenUrl } from "../hetio-frontend-components/build"
import { InfoTable } from "../hetio-frontend-components/build"

import "../css/selected-info.css"

// selected info component
// shows table of info about selected node or edge in graph
export class SelectedInfo extends Component {
  // get fields of info from selected node/edge
  getFields = () => {
    const element = this.props.hoveredElement || this.props.selectedElement

    if (!element) return []

    let order = []
    // get primary fields from top level of node/edge
    let primaryFields = []
    if (element.elementType === "node") {
      primaryFields = ["metanode", "neo4j_id"]
      order = [
        "name",
        "metanode",
        "source",
        "url",
        "description",
        "identifier",
        "neo4j_id",
      ]
    }
    if (element.elementType === "edge") {
      primaryFields = ["kind", "directed", "neo4j_id"]
      order = [
        "kind",
        "name",
        "metanode",
        "source",
        "url",
        "description",
        "identifier",
        "neo4j_id"
      ]
    }

    // get first/second column text (key/value) for each field
    primaryFields = primaryFields.map((field) => {
      return {
        firstCol: field,
        secondCol: String(element[field]),
      }
    })
    // get 'extra fields' from node/edge 'properties' field
    let extraFields = Object.keys(element.properties)
    let properties = element.properties
    // get first/second column text (key/value) for each field
    extraFields = extraFields.map((field) => {
      if (field === "url") {
        const linkUrl = properties[field]
        const linkText = properties[field]
        return {
          firstCol: field,
          secondCol: (
            <a
              className="nowrap"
              target="_blank"
              href={linkUrl}
              onClick={(event) => event.stopPropagation()}
            >
              {linkText}
            </a>
          ),
        }
      } else {
        return {
          firstCol: field,
          secondCol: String(properties[field]),
        }
      }
    })

    // combine primary and extra fields
    let fields = primaryFields.concat(extraFields)

    // display fields in custom order
    fields = sortCustom(fields, order, "firstCol")

    return fields
  }

  // display component
  render() {
    const fields = this.getFields()

    const bodyContents = fields.map((field) => [
      field.firstCol,
      this.props.tooltipDefinitions[field.firstCol],
      field.secondCol,
    ])

    return (
      <div id="graph_info_container"  style={{ width: this.props.width }}>
        {bodyContents.length > 0 && <InfoTable bodyContents={bodyContents} />}
        {!bodyContents.length > 0 && (
          <div className="center light">
            Click on or hover over a node or edge
          </div>
        )}
      </div>
    )
  }
}
// connect component to global state
SelectedInfo = connect((state) => ({
  tooltipDefinitions: state.tooltipDefinitions,
}))(SelectedInfo)
