import { getRandomNodePair } from "../backend-queries.js"
import { lookupNode } from "../backend-queries.js"

// set source and/or target node
export function setSourceTargetNode({
  sourceNode,
  targetNode,
  updateUrl,
  sourceNodeType,
  targetNodeType,
}) {
  return {
    type: "set_source_target_node",
    payload: {
      sourceNode: sourceNode,
      targetNode: targetNode,
      sourceNodeType: sourceNodeType,
      targetNodeType: targetNodeType,
      updateUrl: updateUrl,
    },
  }
}

export function setSearchResults({
  searchResults,
}) {
  return {
    type: "set_search_results",
    payload: {
      searchResults: searchResults,
    },
  }
}

// swap source/target node
export function swapSourceTargetNode() {
  return {
    type: "swap_source_target_node",
  }
}

// fetch random pair of source/target nodes
export function fetchRandomNodePair() {
  return async function (dispatch) {
    const pair = await getRandomNodePair()
    const sourceNode = await lookupNode(pair.source_id)
    const targetNode = await lookupNode(pair.target_id)

    dispatch(
      setSourceTargetNode({
        sourceNode: sourceNode,
        targetNode: targetNode,
        updateUrl: true,
      })
    )
  }
}

export function fetchSourceNode({ sourceNode }) {
  return async function (dispatch) {
    let sourceNode2 = ""
    if (sourceNode && sourceNode.id) {
      sourceNode2 = await lookupNode(sourceNode.id)
    }

    dispatch(
      setSourceTargetNode({
        sourceNode: sourceNode2,
        updateUrl: true,
      })
    )
  }
}

export function fetchSourceNodeType({ sourceNodeType }) {
  return async function (dispatch) {
    dispatch(
      setSourceTargetNode({
        sourceNodeType: sourceNodeType,
        updateUrl: true,
      })
    )
  }
}

export function fetchTargetNode({ targetNode, updateUrl }) {
  return async function (dispatch) {
    let targetNode2 = ""
    if (targetNode && targetNode.id) {
      targetNode2 = await lookupNode(targetNode.id)
    }
    dispatch(
      setSourceTargetNode({
        targetNode: targetNode2,
        updateUrl: updateUrl,
      })
    )
  }
}

export function fetchTargetNodeType({ targetNodeType }) {
  return async function (dispatch) {
    dispatch(
      setSourceTargetNode({
        targetNodeType: targetNodeType,
        updateUrl: true,
      })
    )
  }
}

export function fetchSearchResults({ searchResults }) {
  return async function (dispatch) {
    dispatch(
      setSearchResults({
        searchResults: searchResults,
      })
    )
  }
}
