import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { IconButton } from '../hetio-frontend-components/build';
import { download } from './download.js';
import { togglePrecomputedMetapathsOnly } from './actions.js';

// table "attic" component
// contains buttons and info above table
export class ConnectivitiesAttic extends Component {
  // display component
  render() {
    // results/selected info
    const connectivitieCount = this.props.connectivities.length;
    const connectivitieSelectedCount = this.props.connectivities.filter(
      (metapath) => metapath.checked
    ).length;

    return (
      <div className='table_attic'>
        {connectivitieCount > 0 && (
          <div className='small light left'>
            {connectivitieCount} results, {connectivitieSelectedCount} selected
          </div>
        )}
        {connectivitieCount> 0 && (
          <IconButton
            text='.csv'
            icon={faDownload}
            className='link_button small'
            onClick={() =>
              download(
                this.props.startNode,
                this.props.endNode,
                this.props.connectivities
              )
            }
            tooltipText='Download table as .csv file'
          />
        )}
        {/*<IconButton
          text='precomputed only'
          icon={faCheck}
          className='link_button small'
          onClick={() => this.props.dispatch(togglePrecomputedMetapathsOnly())}
          checked={this.props.precomputedMetapathsOnly}
          tooltipText='Whether to show only precomputed metapaths, or show all
            metapaths. Warning: showing all can be slow.'
        />*/}
        {/*this.props.metapaths.length > 0 && (
          <IconButton
            text={this.props.showMore ? 'collapse' : 'expand'}
            icon={this.props.showMore ? faAngleLeft : faAngleRight}
            className='link_button small'
            onClick={this.props.toggleShowMore}
            tooltipText='Expand table and show more columns'
        />
        )*/}
      </div>
    );
  }
}
// connect component to global state
ConnectivitiesAttic = connect((state) => ({
  connectivities: state.connectivities,
  precomputedMetapathsOnly: state.precomputedMetapathsOnly,
  startNode: state.startNode,
  endNode: state.endNode,
}))(ConnectivitiesAttic);
