import React from "react"
import { Component } from "react"
import { connect } from "react-redux"

import { CollapsibleSection } from "../components/collapsible-section.js"
import { ConnectivitiesAttic } from "./attic.js"
import { ConnectivitiesTable } from "./table.js"
import { Loading } from "../components/loading.js"

// path results section component
export class ConnectivitiesResults extends Component {
  // initialize component
  constructor() {
    super()

    this.state = {}
    this.state.showMore = false
  }

  // toggle expanded table
  toggleShowMore = (event) => {
    if (event) event.preventDefault()

    this.setState({ showMore: !this.state.showMore })
  }

  // display component
  render() {
    let placeholder = <></>
    if (!this.props.sourceNode.id || !(this.props.searchResults.length > 0)) {
      placeholder = (
        <span className="light">select a source and target node</span>
      )
    } else if (
      this.props.connectivities &&
      this.props.connectivities.length === 0
    )
      placeholder = <span className="light">no results to show</span>

    return (
      <CollapsibleSection
        label="Connectivities"
        tooltipText="Connectivities of length <= 3 between the source and target
        node"
      >
        {this.props.connectivitiesLoading ? <Loading color="#02b3e4" /> : ""}
        <ConnectivitiesAttic
          showMore={this.state.showMore}
          toggleShowMore={this.toggleShowMore}
        />
        {!this.props.connectivitiesLoading &&
          this.props.connectivities &&
          this.props.connectivities.length > 0 && (
            <ConnectivitiesTable showMore={this.state.showMore} />
          )}
        {/*this.props.connectivitiesLoading ? "" : placeholder */}
      </CollapsibleSection>
    )
  }
}
// connect component to global state
ConnectivitiesResults = connect((state) => ({
  searchResults: state.searchResults,
  metapaths: state.metapaths,
  connectivities: state.connectivities,
  sourceNode: state.sourceNode,
  connectivitiesLoading: state.connectivitiesLoading,
}))(ConnectivitiesResults)
