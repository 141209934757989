import React from "react"
import { Component } from "react"
import { connect } from "react-redux"

import { SearchBoxChk } from "./search-box-chk.js"
import { SearchTypeBox } from "./search-type-box.js"
import { Nodes } from "./nodes.js"
import {
  fetchSearchResults,
  fetchTargetNodeType,
  fetchTargetNode,
} from "./actions.js"

function unique(arr, val) {
  let repeat = false
  if (arr.length === 0) {
    arr.push(val)
    return arr
  }
  if (arr.length > 50) {
    return arr
  }
  for (const key in arr) {
    if (arr.hasOwnProperty(key)) {
      const obj = arr[key]
      if (val.id === obj.id) {
        repeat = true
      }
    }
  }
  if (!repeat) {
    arr.unshift(val)
  }
  return arr
}

// source node search box component
export class TargetNode extends Component {
  // initialize component
  constructor(props) {
    super(props)

    this.state = {}
    this.inputRef = React.createRef()
  }

  // when user makes a new node selection
  onChange = (value) => {
    if (value) {
      let searchResults =
        this.props.searchResults === undefined ? [] : this.props.searchResults
      let ones = unique(searchResults, value)
      if (ones.length > 50) {
        alert("Cannot search more than 50 targets!")
      } else {
        this.props.dispatch(fetchSearchResults({ searchResults: ones }))
        this.props.dispatch(
          fetchTargetNode({ targetNode: value, updateUrl: false })
        )
      }
    }
    // unfocus search box on selection
    if (value) document.activeElement.blur()
  }
  // when user makes a new node selection
  onTypeChange = (value) => {
    this.props.dispatch(
      fetchTargetNodeType({ targetNodeType: value, updateUrl: true })
    )
    // unfocus search box on selection
    if (value) document.activeElement.blur()
  }

  // display component node={this.props.node}
  render() {
    return (
      <div className="source_node_block">
        <SearchTypeBox
          label="Target Node"
          tooltipText="Category"
          node={this.props.targetNodeType}
          onChange={this.onTypeChange}
        />
        <SearchBoxChk
          label=""
          tooltipText="The ending node of the paths"
          nodeType={this.props.targetNodeType}
          otherNode={this.props.otherNode}
          onChange={this.onChange}
        />
        <Nodes searchResults={this.props.searchResults} />
      </div>
    )
  }
}
// connect component to global state
TargetNode = connect((state) => ({
  node: state.targetNode,
  otherNode: state.sourceNode,
  targetNodeType: state.targetNodeType,
  searchResults: state.searchResults,
}))(TargetNode)
