import { makeFilenameFriendly } from "../hetio-frontend-components/build"
import { sortCustom } from "../hetio-frontend-components/build"
import { downloadCsv } from "../hetio-frontend-components/build"

// download metapath table
export function download(sourceNode, targetNode, metapaths) {
  if (!metapaths.length || !sourceNode) return

  const tableData = makeTable(metapaths)
  const filename = [
    "metapaths",
    makeFilenameFriendly(sourceNode),
    makeFilenameFriendly(targetNode),
  ].join("_")

  downloadCsv(tableData, filename)
}

// make table array out of object
function makeTable(metapaths) {
  let headers = []
  for (const field of Object.keys(metapaths[0])) {
    if (
      typeof metapaths[0][field] === "number" ||
      typeof metapaths[0][field] === "string"
    )
      headers.push(field)
  }

  // remove undesired fields
  headers = headers.filter((header, key) => {
    return header !== "connectivities"
  })

  // sort headers in custom order
  const order = ["id", "path_count"]
  headers = sortCustom(headers, order)

  const table = []
  table.push(headers)
  for (const result of metapaths) {
    const row = []
    for (const header of headers) row.push(result[header])
    table.push(row)
  }

  return table
}
