// reducer for state.sourceNode
// takes whole state as input
export function sourceNode(state = {}, action) {
  switch (action.type) {
    case "set_source_target_node":
      if (action.payload.sourceNode !== undefined)
        return action.payload.sourceNode || {}
      else return state.sourceNode || {}
    case "swap_source_target_node":
      return state.targetNode || {}
    default:
      return state.sourceNode || {}
  }
}

// reducer for state.targetNode
// takes whole state as input
export function targetNode(state = {}, action) {
  switch (action.type) {
    case "set_source_target_node":
      if (action.payload.targetNode !== undefined)
        return action.payload.targetNode || {}
      else return state.targetNode || {}
    case "swap_source_target_node":
      return state.sourceNode || {}
    default:
      return state.targetNode || {}
  }
}

export function sourceNodeType(state = {}, action) {
  switch (action.type) {
    case "set_source_target_node":
      if (action.payload.sourceNodeType !== undefined)
        return action.payload.sourceNodeType || {}
      else return state.sourceNodeType || {}
    case "swap_source_target_node":
      return state.sourceNodeType || {}
    default:
      return state.sourceNodeType || {}
  }
}

export function targetNodeType(state = {}, action) {
  switch (action.type) {
    case "set_source_target_node":
      if (action.payload.targetNodeType !== undefined)
        return action.payload.targetNodeType || {}
      else return state.targetNodeType || {}
    case "swap_source_target_node":
      return state.targetNodeType || {}
    default:
      return state.targetNodeType || {}
  }
}

export function searchResults(state = {}, action) {
  switch (action.type) {
    case "set_search_results":
      if (action.payload.searchResults !== undefined)
        return action.payload.searchResults || []
      else return state.searchResults || []
    default:
      return state.searchResults || []
  }
}
