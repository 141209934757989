import { searchConnectivities } from "../backend-queries.js"
import { searchPaths } from "../backend-queries.js"
import { copyObject } from "../hetio-frontend-components/build"

// fetch metapaths function
export async function fetchConnectivities({
  sourceNodeId,
  targetNodeIds,
  precomputedOnly,
  updateUrl,
  preserveChecks,
}) {
  const connectivities = await searchConnectivities(
    sourceNodeId,
    targetNodeIds,
    !precomputedOnly
  )
  return {
    connectivities: connectivities,
    updateUrl: updateUrl,
    preserveChecks: preserveChecks,
  }
}

// set metapaths action
export function setConnectivities({
  connectivities,
  updateUrl,
  preserveChecks,
}) {
  return {
    type: "set_connectivities",
    payload: {
      connectivities: connectivities,
      updateUrl: updateUrl,
      preserveChecks: preserveChecks,
    },
  }
}

// fetch and set metapaths action creator
export function fetchAndSetConnectivities({
  sourceNodeId,
  targetNodeIds,
  precomputedOnly,
  updateUrl,
  preserveChecks,
}) {
  return async function (dispatch) {
    const connectivities = await fetchConnectivities({
      sourceNodeId: sourceNodeId,
      targetNodeIds: targetNodeIds,
      precomputedOnly: precomputedOnly,
      updateUrl: updateUrl,
      preserveChecks: preserveChecks,
    })
    dispatch(setConnectivities(connectivities, updateUrl, preserveChecks))
  }
}

// toggle precomputedMetapathsOnly action
export function togglePrecomputedMetapathsOnly() {
  return {
    type: "toggle_precomputed_metapaths_only",
    payload: {
      updateUrl: true,
    },
  }
}

// set precomputedMetapathsOnly action
export function setPrecomputedMetapathsOnly({
  precomputedMetapathsOnly,
  updateUrl,
}) {
  return {
    type: "set_precomputed_metapaths_only",
    payload: {
      precomputedMetapathsOnly: precomputedMetapathsOnly,
      updateUrl: updateUrl,
    },
  }
}
