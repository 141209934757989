export const minWidth = 240;
export const minHeight = 240;
export const maxWidth = 3840;
export const maxHeight = 2160;
export const minZoom = 0.25;
export const maxZoom = 4;
export const nodeRadius = 25;
export const nodeDistance = 100;
export const nodeCharLimit = 25;
export const nodeFontSize = 8;
export const nodeRepulsion = 200;
export const centeringForce = 2;
export const edgeFontSize = 8;
export const edgeThickness = 2;
export const edgeArrowSize = 10;
export const edgeSpreadDistance = 20;
export const edgeSpreadAngle = (45 / 360) * 2 * Math.PI;
export const inkColor = '#424242';
export const backgroundColor = '#fafafa';
export const highlightColor = '#ffcc00';
export const gridSpacing = 25;
export const gridMultiple = 4;
export const showGrid = false;
