import React from "react"
import { Component } from "react"
import { connect } from "react-redux"

import { SearchBox } from "./search-box.js"
import { SearchTypeBox } from "./search-type-box.js"
import { fetchSourceNode, fetchSourceNodeType } from "./actions.js"

// source node search box component
export class SourceNode extends Component {
  // when user makes a new node selection
  onChange = (value) => {
    this.props.dispatch(fetchSourceNode({ sourceNode: value, updateUrl: true }))
    // unfocus search box on selection
    if (value) document.activeElement.blur()
  }

  // when user makes a new node selection
  onTypeChange = (value) => {
    this.props.dispatch(
      fetchSourceNodeType({ sourceNodeType: value, updateUrl: true })
    )
    this.props.dispatch(fetchSourceNode({ sourceNode: undefined, updateUrl: true }))
    // unfocus search box on selection
    if (value) document.activeElement.blur()
  }

  // display component
  render() {
    return (
      <div className="source_node_block">
        <SearchTypeBox
          label="Source Node"
          tooltipText="Category"
          node={this.props.sourceNodeType}
          onChange={this.onTypeChange}
        />
        <SearchBox
          label=""
          tooltipText="The starting node of the paths"
          nodeType={this.props.sourceNodeType}
          node={this.props.node}
          otherNode={this.props.otherNode}
          onChange={this.onChange}
        />
      </div>
    )
  }
}
// connect component to global state
SourceNode = connect((state) => ({
  node: state.sourceNode,
  otherNode: state.targetNode,
  sourceNodeType: state.sourceNodeType,
}))(SourceNode)
